.goalCardContainer {
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
}
.goalCardHeader {
    display: flex;
    justify-content: space-between;
}
.cardTitle {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
}
.goalCardTopRight {
    display: flex;
}