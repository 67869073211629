/* card-styles */
.myClubCard1 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-top-left-radius: 16px; /* Curvy top-left corner */
    border-top-right-radius: 16px; /* Curvy top-right corner */
    font-size: 12px;
    width: 200px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    border: 0.2px solid #cccccc; /* Add border to the card */
  }
  
  .myClubCardTopSection1 {
    display: flex;
    flex-direction: column; /* Stack the image and name vertically */
    align-items: center; /* Center align content horizontally */
    margin-bottom: 5px;
  }
  
  .clubCardClubName1 {
    font-weight: 600;
    font-style: 'Montserrat';
    margin-top: 5px; /* Small gap between the image and name */
  }
  
  .myClubCard1 img {
    width: 60px;
    height: 60px;
    margin-bottom: 5px; /* Gap between the image and the name */
  }
  
  .cardDetail1 {
    display: flex;
    flex-direction: column; /* Align elements in a column */
    text-align: center; /* Center align text */
    justify-content: center;
    width: 100%;
  }
  
  .pinStyle1 {
    position: absolute;
    right: 10px;
    top: 10px; /* Position the pin icon at the top-right corner */
  }
  