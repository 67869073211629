.reportsHeader {
    display: flex;
    justify-content: left;
}
.reportsMainContentWrapper {
    display: flex;
    margin-top: 10px;
}

.reportsFoldersWrapper {
    width: 20%;
}
.reportAreaWrapper {
    width: 70%;
}